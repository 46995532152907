<div class="toolbar-user" (furyClickOutside)="onClickOutside()" fxFlex="grow">
    <button class="button" mat-button (click)="toggleDropdown()" [class.open]="isOpen" fxFlex="grow">
    <span fxLayout="row" fxLayoutAlign="start center">
      <mat-icon _ngcontent-iyu-c140="" role="img" id="icnAccCir" class="mat-icon notranslate avatar material-icons-outlined mat-icon-no-color" aria-hidden="true" speechify-initial-font-family="&quot;Material Icons Outlined&quot;" speechify-initial-font-size="40px">account_circle</mat-icon>
      <span class="name" fxHide fxShow.gt-sm>Jared Naicker</span>
      <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </span>
  </button>

  <div class="dropdown" [class.open]="isOpen">
    <div class="content">
      <div class="list">
        <div class="list-item" matRipple>
          <mat-icon class="list-item-icon">account_circle</mat-icon>
          <span>Profile</span>
        </div>
        <div class="list-item" matRipple>
          <mat-icon class="list-item-icon">settings</mat-icon>
          <span>Settings</span>
        </div>
        <div class="list-item" matRipple>
          <mat-icon class="list-item-icon">help</mat-icon>
          <span>Help</span>
        </div>
        <mat-divider></mat-divider>
        <div class="list-item" matRipple [routerLink]="['/login']">
          <mat-icon class="list-item-icon">exit_to_app</mat-icon>
          <span>Logout</span>
        </div>
      </div>
    </div>
  </div>
</div>
