<div class="sidenav" fxLayout="column">
  <div class="sidenav-toolbar" fxLayout="row" fxLayoutAlign="start center">
    <div class="sidenav-toolbar-icon">
    </div>
    <div [routerLink]="['/']" class="sidenav-toolbar-logo">
      <img src="https://images.squarespace-cdn.com/content/v1/5934d2ae6b8f5beeb5ba23f3/367449c7-07ca-41aa-8172-1e9e4a2206a3/AVZ+Minerals+logo.png?format=1500w" width="135px" height="55px" alt="Logo">
    </div>


    <span fxFlex><!-- fill space --></span>

    <div fxFlexAlign="center" fxHide fxShow.gt-md>
      <button (click)="toggleCollapsed()" class="sidenav-toolbar-collapse-toggle" mat-icon-button>
        <mat-icon *ngIf="!collapsed" class="sidenav-toolbar-collapse-icon">radio_button_checked</mat-icon>
        <mat-icon *ngIf="collapsed" class="sidenav-toolbar-collapse-icon">radio_button_unchecked</mat-icon>
      </button>
    </div>
  </div>

  <fury-scrollbar class="sidenav-items" fxFlex="auto">
    <div *ngIf="sidenavUserVisible$ | async" class="sidenav-user">
    </div>

    <fury-sidenav-item *ngFor="let item of items$ | async" [item]="item" [level]="0"></fury-sidenav-item>
  </fury-scrollbar>

</div>
