import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { ThemeService } from '../@fury/services/theme.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { SplashScreenService } from '../@fury/services/splash-screen.service';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private sidenavService: SidenavService,
              private iconRegistry: MatIconRegistry,
              private renderer: Renderer2,
              private themeService: ThemeService,
              @Inject(DOCUMENT) private document: Document,
              private platform: Platform,
              private route: ActivatedRoute,
              private splashScreenService: SplashScreenService) {
    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.themeService.setStyle(queryParamMap.get('style')));

    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    this.themeService.theme$.subscribe(theme => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);
      }

      this.renderer.addClass(this.document.body, theme[1]);
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.sidenavService.addItems([
      {
        name: 'Dashboard',
        routeOrFunction: '/',
        icon: 'dashboard',
        position: 10,
        pathMatchExact: true
      },
      {
        name: 'Active Picking List',
        routeOrFunction: '/tables/all-in-one-table',
        icon: 'assignment',
        badge: '5',
        badgeColor: ' #ffb133',
        position: 15,
      },
      {
        name: 'Confirmed Picking List',
        routeOrFunction: '/apps/calendar',
        icon: 'assignment_turned_in',
        badge: '3',
        badgeColor: ' #31FF36',
        position: 20
      },
      {
        name: 'Map',
        routeOrFunction: '/apps/inbox',
        icon: 'location_on',
        position: 25
      },
      {
        name: 'Reports',
        routeOrFunction: '/apps/chat',
        icon: 'library_books',
        position: 30,
      },
      {
        name: 'User Management',
        icon: 'group',
        position: 66,
        subItems: [
          {
            name: 'Manage Users',
            routeOrFunction: '/login',
            position: 5
          },
          {
            name: 'Create Roles',
            routeOrFunction: '/register',
            position: 10
          },
          {
            name: 'Assign Roles',
            routeOrFunction: '/forgot-password',
            position: 15
          }
        ]
      },
      {
        name: 'Administration',
        icon: 'settings_applications',
        position: 67,
        subItems: [
          {
            name: 'Simple',
            routeOrFunction: '/page-layouts/simple',
            position: 5
          },
          {
            name: 'Simple Tabbed',
            routeOrFunction: '/page-layouts/simple-tabbed',
            position: 5
          },
          {
            name: 'Card',
            routeOrFunction: '/page-layouts/card',
            position: 10
          },
          {
            name: 'Card Tabbed',
            routeOrFunction: '/page-layouts/card-tabbed',
            position: 15
          },
        ],
      },
    ]);
  }
}
